@import "assets/scss/app.scss";
@import url("https://fonts.googleapis.com/css2?family=Chewy&display=swap");

body {
  margin: 0;
  padding: 0;
  // background: #f3f2ef;
  font-family: work-Sans, sans-serif;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

// Stylings for footer logo
.bar {
  background: 7c7c7c;
  width: 100%;
  height: 60px;
  text-align: center;
}

.circle {
  position: relative;
  top: -10px;
  border-radius: 100%;
  background: white;
  width: 60px;
  height: 60px;
  margin: 0 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: black;
  transition: box-shadow ease-in 150ms;
  background: #7c7c7c;
  border: 0;
  outline: none;
}

.circle::before,
.circle::after {
  content: "";
  position: absolute;
  top: 4px;
  width: 32px;
  height: 6px;
  background: #7c7c7c;
  z-index: 1;
}

.circle::before {
  left: -18px;
  background: radial-gradient(
    ellipse at 0% -25%,
    transparent 0,
    transparent 70%,
    #7c7c7c 70%,
    #7c7c7c 100%
  );
}

.circle::after {
  right: -18px;
  background: radial-gradient(
    ellipse at 100% -25%,
    transparent 0,
    transparent 70%,
    #7c7c7c 70%,
    #7c7c7c 100%
  );
}

//Stylings for home page hub section
.show-sm {
  display: block;
  @media (max-width: 800px) {
    display: block;
    margin-top: 10%;
  }
}
.show-lg {
  display: block;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
  @media (max-width: 800px) {
    display: none;
  }
}

//stylings for navbar selected page
.navItem {
  width: 11%;
  @media (max-width: 1083px) {
    width: 15%;
  }
  color: white !important;
}
.navItem:hover {
  color: #e4007b !important;
}
.selected {
  color: #e4007b;
  font-weight: bolder;
  padding-bottom: 3px;
}

.swal2-icon.swal2-info {
  border-color: black !important;
  color: black !important;
}

//stylings for calendar
.react-datepicker {
  box-shadow: 0 4px 14px gray;
}

.react-datepicker .react-datepicker__current-month {
  color: black !important;
}

.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: black !important;
}
.react-datepicker .react-datepicker__navigation--next {
  border-left-color: black !important;
}

.react-datepicker .react-datepicker__day--today,
.react-datepicker .react-datepicker__day--selected,
.recent-notification .media .media-body:before,
.react-datepicker .react-datepicker__day--selected:hover {
  background-color: black !important;
}

ul.notification-dropdown.onhover-show-div {
  width: 450px;
  right: -18px;
  left: initial;
  border-radius: 16px;
}

.css-df17o1 {
  position: absolute;
  height: 110% !important;
  width: 100% !important;
  top: -70px !important;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  font-size: 1.2em;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1001 !important;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
  opacity: 1;
}

.card-header {
  border-bottom: 3px solid #f6f7fb !important;
  text-transform: none !important;
}

.card-header,
.card-body {
  padding: 20px !important;
}

.cal-date-widget {
  padding-bottom: 0px !important;
}

// .card-body {
//   color: grey !important;
// }

.main-wrapper {
  height: 60vh;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.pac-container {
  z-index: 2000;
}

// .right-sidebar {
//   width: 420px;
//   right: -420px;
// }
//dark theme colors
body.dark-only .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user {
  background-color: $dark-body-background !important;
}

body.dark-only .page-wrapper .page-main-header .main-header-right {
  background-color: $dark-body-background !important;
}
body.dark-only
  .page-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .search-form
  input {
  background-color: #1a1a1a;
}

body.dark-only .react-datepicker {
  background-color: $dark-body-background !important;
}

body.dark-only .react-datepicker .react-datepicker__current-month {
  color: #ffff !important;
}

body.dark-only .react-datepicker .react-datepicker__navigation--previous {
  border-right-color: #ffff !important;
}
body.dark-only .react-datepicker .react-datepicker__navigation--next {
  border-left-color: #ffff !important;
}
body.dark-only .react-datepicker__day,
body.dark-only .react-datepicker__day-name {
  color: #ffff !important;
}
body.dark-only .react-datepicker .react-datepicker__day--today,
body.dark-only .react-datepicker .react-datepicker__day--selected,
body.dark-only .recent-notification .media .media-body:before,
body.dark-only .react-datepicker .react-datepicker__day--selected:hover {
  background-color: #ffff !important;
  color: $dark-body-background !important;
}

body.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-sidebar
  .sidebar-menu
  > li
  > a.active {
  color: #fff !important;
}

body.dark-only .page-wrapper .list-group-item {
  border: none !important;
}

body.dark-only .page-wrapper .list-group-item.active {
  background-color: $dark-body-background !important;
  border: none !important;
}

body.dark-only .chat__header,
body.dark-only .chat__list,
body.dark-only .chat__composer {
  background-color: $dark-card-background;
}

body.dark-only .receiver__message__container {
  color: $dark-card-background;
}

.page-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb
  .breadcrumb-item
  a
  svg {
  color: black;
}
body.dark-only
  .page-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb
  .breadcrumb-item
  a
  svg {
  color: white;
}

.add-feed-container {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.cal-date-widget .cal-info h2 {
  font-size: 65px;
}
.rbc-event {
  background-color: white !important;
}

body.dark-only .list__item:hover,
body.dark-only .active_chat,
body.dark-only .list__item__actions {
  background-color: #010101 !important;
  color: white !important;
}

body.dark-only .item__details__last-message,
body.dark-only .item__details__timestamp {
  color: white !important;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}
